import { validateEthTransferCompliance, validateErc20TransferCompliance, validateUser } from '@securely.id/websdk';
import { ComplianceAbi } from './ethereum/contracts/abis/compliance.js';
import { CompliantTreasuryAbi } from './ethereum/contracts/abis/compliant-treasury.js';
import { getControllers } from './ethereum/contracts/compliance.js';
import { Erc20Abi } from './ethereum/contracts/abis/erc20.js';
import { addresses } from './ethereum/contracts/addresses.js';
import { prettyAddress } from './ethereum/utils.js';
import { getChainId, getSigner, getReadonlyProvider, providerInit, onWalletConnect } from './ethereum/wallet.js';
import { loadingSpinner, formatTimestamp, refreshTooltips } from './utils.js';
const ethers = require('ethers');

const etherscanIconUrl = require('/src/assets/images/etherscan-logo-circle.svg');

var decimals = { '0x0000000000000000000000000000000000000000': 18 };
var balances = {};
var currency;
var whitelisted = false;
const defaultCurrencies = ['USDC', 'RWA'];

function auditLink(chainID, verificationReferenceID, display) {
    return `<a href="#" onClick="AuditRequest=window.open('https://app.securely.id/audit/?chain-id=${chainID}&verification-reference-id=${verificationReferenceID}','AuditRequest','width=450,height=560'); return false;">${display}</a>`;
}

function updateCurrencyIcon(currency) {
    switch (currency) {
        case 'ETH':
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/ETH.svg'));
            break;
        case 'MATIC':
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/MATIC.svg'));
            break;
        case 'USDC':
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/USDC.svg'));
            break;
        default:
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/undefined.svg'));
            break;
    }
}

async function templateInjection() {
    updateLink();

    // Fetch the base informations
    const signer = await getSigner();
    $('#wallet-address').html(prettyAddress(signer.address));
    const readonlyProvider = await getReadonlyProvider();
    const chainId = await getChainId();
    const etherscan = addresses[chainId].getAddress('etherscan');
    const dappAddress = addresses[chainId].getAddress('compliantTreasury');
    $('#dapp-contract-address').html(`<a href="${etherscan}/address/${dappAddress}">${dappAddress}</a>`);
    $('#policies-explorer-link').attr('href', `https://dashboard.securely.id/explorer/?chainid=${chainId}&address=${dappAddress}`);
    const compliantTreasury = new ethers.Contract(dappAddress, CompliantTreasuryAbi, readonlyProvider);
    const complianceAddress = await compliantTreasury.compliance();
    $('#compliance-contract-address').html(`<a href="${etherscan}/address/${complianceAddress}">${complianceAddress}</a>`);
    const compliance = new ethers.Contract(complianceAddress, ComplianceAbi, readonlyProvider);
    try {
        const whitelistedRole = await compliantTreasury.WHITELISTED_ROLE();
        whitelisted = await compliantTreasury.hasRole(whitelistedRole, signer.address);
    } catch (error) {
        console.warn('Failed to check whitelisted role:', error.message);
    }
    if (whitelisted) {
        $('#principal-title').text('Compliant');
        $('#superficial-title').text('Unverified');
        $('#deposit-block').hide();
        $('#payment-link-label').text('Share this link:');
    } else {
        $('#principal-title').text('My Vault');
        $('#superficial-title').text('Connected Wallet');
        $('#superficial-col').hide();
        $('#principal-col').removeClass('col-8').addClass('col-12');
        $('#payment-link-label').text('Want someone else to deposit? Share this link:');
    }

    // Display the compliance details
    const controllers = await getControllers(compliance);
    const controllersLineBreak = Object.keys(controllers).length > 1 ? '<br />' : '';
    $('#compliance-controllers').empty();
    for (const controller in controllers)
        $('#compliance-controllers').append(`${controllersLineBreak}<a href="${etherscan}/address/${controller}">${controller}</a>`);

    // Build the currency decimals dictionary
    const compliantPaymentsPromise = compliantTreasury.queryFilter(compliantTreasury.filters.CompliantPayment());
    const compliantPayments = (await compliantPaymentsPromise).reverse();
    balances = {};
    for (var i = 0; i < compliantPayments.length; i++) {
        const compliantPayment = compliantPayments[i];
        const { sender, destination, currency, complianceFullHash } = compliantPayment.args;
        if (decimals[currency] === undefined) {
            try {
                const erc20 = new ethers.Contract(currency, Erc20Abi, readonlyProvider);
                decimals[currency] = await erc20.decimals();
                balances[currency] = await erc20.balanceOf(signer.address);
            }
            catch (error) {
                console.warn('Failed to get decimals (defaulting to 18):', error.message);
                decimals[currency] = 18;
            }
        }
        if (destination == signer.address || sender == signer.address)
            compliantPayment.complianceDetails = await compliance.queryFilter(compliance.filters.ComplianceRegistered(dappAddress, complianceFullHash));
    }
    balances['0x0000000000000000000000000000000000000000'] = await signer.provider.getBalance(signer.address);
    for (let prettyCurrency of defaultCurrencies) {
        const currency = addresses[chainId].getAddress(prettyCurrency);
        const erc20 = new ethers.Contract(currency, Erc20Abi, readonlyProvider);
        try {
            decimals[currency] = await erc20.decimals();
        }
        catch (error) {
            console.warn('Failed to get decimals (defaulting to 18):', error.message);
            decimals[currency] = 18;
        }
        try {
            balances[currency] = await erc20.balanceOf(signer.address);
        }
        catch (error) {
            console.warn(`Failed to get balance for ${prettyCurrency} (defaulting to 0):`, error.message);
            balances[currency] = BigInt(0);
        }
    }
    console.log("decimals", decimals);

    // Build the balances dictionary
    var compliantBalances = { '0x0000000000000000000000000000000000000000' : BigInt(0) };
    for (let prettyCurrency of defaultCurrencies)
        compliantBalances[addresses[chainId].getAddress(prettyCurrency)] = BigInt(0);
    for (var i = 0; i < compliantPayments.length; i++) {
        const compliantPayment = compliantPayments[i];
        const { destination, currency, amount } = compliantPayment.args;
        if (compliantBalances[currency] === undefined) {
            if (balances[currency] === undefined)
                balances[currency] = BigInt(0);
            compliantBalances[currency] = BigInt(0);
        }
        if (destination == signer.address)
            compliantBalances[currency] = compliantBalances[currency] + BigInt(amount);
    }
    const withdrawalsPromise = compliantTreasury.queryFilter(compliantTreasury.filters.Withdrawal(signer.address));
    const withdrawals = await withdrawalsPromise;
    for (var i = 0; i < withdrawals.length; i++) {
        const withdrawal = withdrawals[i];
        const { amount, currency } = withdrawal.args;
        compliantBalances[currency] = (compliantBalances[currency] || BigInt(0)) - BigInt(amount);
    }

    // Display the balances table
    $('#balances').empty();
    $('#balances-details').empty();
    for (let token in compliantBalances) {
        var compliantBalance = compliantBalances[token];
        var unverifiedBalance = balances[token];
        if (whitelisted) {
            if (compliantBalance > balances[token])
                compliantBalance = balances[token];
            unverifiedBalance -= compliantBalance;
        }
        const prettyCurrency = addresses[chainId].getPrettyName(token, true, true, false);
        var prettyComplianceBalance = ethers.formatUnits(compliantBalance, decimals[token]);
        prettyComplianceBalance = (+prettyComplianceBalance).toFixed(4).replace(/\.0000$/, '');
        var prettyUnverifiedBalance = ethers.formatUnits(unverifiedBalance, decimals[token]);
        prettyUnverifiedBalance = (+prettyUnverifiedBalance).toFixed(4).replace(/\.0000$/, '');
        $('#balances').append(`<tr>
            <td>${prettyComplianceBalance}</td>
            <td>${prettyCurrency}</td>
            <td class="w-100"></td>
            <td class="nowrap">
                <a href="#" class="transfer" data-currency="${addresses[chainId].getPrettyName(token, false)}" data-bs-toggle="modal" data-bs-target="#transferModal"><i class="fa-solid fa-upload"></i></a>
                &nbsp;&nbsp;
                <a href="#" class="receive" data-currency="${addresses[chainId].getPrettyName(token, false)}" data-bs-toggle="modal" data-bs-target="#receiveModal"><i class="fa-solid fa-download"></i></a>
            </td>
        </tr>`);
        $('#balances-details').append(`<tr>
            <td class="w-50"></td>
            <td class="superficial">${prettyUnverifiedBalance}</td>
            <td class="superficial">${prettyCurrency}</td>
            <td class="w-50"></td>
        </tr>`);
    }
    $('.transfer').on('click', function() { prepareTransfer($(this).data('currency')); });
    $('.receive').on('click', function() { prepareReceive($(this).data('currency')); });

    // Check if the approvals column should be displayed
    const approvalsPromise = compliance.queryFilter(compliance.filters.ApprovalRequired(dappAddress));
    const approvals = (await approvalsPromise).reverse();
    if (approvals.length > 0)
        $('#approvals-column').show();

    // Build & display the payment history table
    $('#payment-history').empty();
    for (var i = 0; i < compliantPayments.length; i++) {
        const compliantPayment = compliantPayments[i];
        const { sender, destination, currency, amount, complianceFullHash } = compliantPayment.args;
        if (destination != signer.address && sender != signer.address)
            continue;
        const complianceDetails = compliantPayment.complianceDetails;
        const comData = complianceDetails.length > 0 ? JSON.parse(complianceDetails[0].args.complianceDetails) : "";
        const timestamp = (await signer.provider.getBlock(compliantPayment.blockNumber)).timestamp;
        var richArgs = `
            <!--Compliance hash: ${auditLink(chainId, complianceFullHash)}<br />-->
            <!--Transaction hash: <br />-->
        `;
        for (var j = 0; j < comData.length; j++) {
            const comSubData = comData[j];
            richArgs += `<span data-bs-toggle="tooltip" data-bs-title="Ref: ${comSubData.ref}" data-bs-placement="top">
                ${auditLink(chainId, comSubData.ref, `${comSubData.type} (${comSubData.providerName})`)}
                </span><br />`;
        }
        var approvalDate = 'Automatic';
        if (approvals.length > 0) {
            const approvalVerdicts = await compliance.queryFilter(compliance.filters.ComplianceVerdict(dappAddress, complianceFullHash));
            if (approvalVerdicts.length > 0) {
                const approvalTimestamp = (await signer.provider.getBlock(approvalVerdicts[0].blockNumber)).timestamp;
                approvalDate = formatTimestamp(Number(approvalTimestamp));
            }
        }
        const color = destination == signer.address ? 'green' : '#B00000';
        const sign = destination == signer.address ? '+' : '-';
        const prettyAmount = ethers.formatUnits(amount, decimals[currency]).replace(/\.0$/, '');
        const prettyCurrency = addresses[chainId].getPrettyName(currency);
        const direction = destination == signer.address ? 'from' : 'to';
        const counterpart = prettyAddress(destination == signer.address ? sender : destination, true, false);
        const approval = approvals.length > 0 ? ` (approved ${approvalDate})` : '';
        $('#payment-history').append(`<tr>
            <td style="color: ${color}" class="nowrap">${sign}</td>
            <td style="color: ${color}" class="nowrap">${prettyAmount}</td>
            <td style="color: ${color}" class="nowrap">${prettyCurrency}</td>
            <td class="nowrap">${direction}</td>
            <td class="nowrap"><b>${counterpart}</b></td>
            <td class="nowrap">${formatTimestamp(Number(timestamp))}</td>
            <td class="nowrap">${approval}</td>
            <td class="nowrap"></td>
            <td class="nowrap">${richArgs}</td>
            <td class="nowrap"></td>
            <td class="nowrap">
                ${auditLink(chainId, compliantPayment.transactionHash, '<i class="fa-solid fa-receipt" data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="Request<br />compliance data" data-bs-placement="top"></i>')}&nbsp;
                <a href="${etherscan}/tx/${compliantPayment.transactionHash}" target="_blank" data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="View on<br />Etherscan">
                    <img height="20px" src="${etherscanIconUrl}" alt="View on Etherscan" />
                </a>
            </td>
        </tr>`);
    }

    // Refresh the UI elements
    refreshTooltips();
}

function errorPopup(message) {
    console.error('Error:', message);
    $('#error-toast-body').text(message);
    new bootstrap.Toast($('#error-toast')).show();
    $('#pay-button').prop('disabled', false);
    $('#pay-button').html('Pay');
}

function warningPopup(message) {
    console.warn('Warning:', message);
    $('#warning-toast-body').text(message);
    new bootstrap.Toast($('#warning-toast')).show();
    $('#pay-button').prop('disabled', false);
    $('#pay-button').html('Pay');
}

function successPopup(message) {
    console.info('Success:', message);
    $('#success-toast-body').text(message);
    new bootstrap.Toast($('#success-toast')).show();
}

function prepareTransfer(prettyCurrency) {
    currency = prettyCurrency;
    console.log('Transfer:', currency);
    updateCurrencyIcon(currency);
    $('.currency').text(prettyAddress(currency, false, false));
    $('#transfer-amount-input').val('');
    $('#transfer-recipient-input').val('');
}

function prepareReceive(prettyCurrency) {
    currency = prettyCurrency;
    console.log('Receive:', currency);
    updateCurrencyIcon(currency);
    updateLink();
    $('.currency').text(prettyAddress(currency, false, false));
    $('#receive-amount-input').val('');
}

async function transfer() {
    $('#transfer-button').prop('disabled', true);
    $('#transfer-button').html(loadingSpinner);
    const signer = await getSigner();
    const chainId = (await signer.provider.getNetwork()).chainId;
    const compliantTreasuryAddress = addresses[chainId].getAddress('compliantTreasury');
    const compliantTreasury = new ethers.Contract(compliantTreasuryAddress, CompliantTreasuryAbi, signer);
    const currencyAddress = addresses[chainId].getAddress(currency);
    try {
        const amount = ethers.parseUnits($('#transfer-amount-input').val(), decimals[currencyAddress]);
        const recipient = $('#invoicer-input').val();
        if (whitelisted) {
            if (currency == 'ETH')
                await compliantTreasury.payEthers(recipient, { value: amount });
            else {
                const token = new ethers.Contract(currencyAddress, Erc20Abi, signer);
                await token.approve(compliantTreasuryAddress, amount);
                await compliantTreasury.payTokens(recipient, currencyAddress, amount);
            }
        } else {
            const selector = compliantTreasury.interface.getFunction('transferTo', [recipient, currencyAddress, amount]).selector;
            if (currency == 'ETH')
                await validateEthTransferCompliance(chainId, compliantTreasuryAddress, selector, signer.address, recipient, amount);
            else
                await validateErc20TransferCompliance(chainId, compliantTreasuryAddress, selector, signer.address, recipient, currencyAddress, amount);
            await compliantTreasury.transferTo(recipient, currencyAddress, amount);
        }
        const withdrawLink = `${window.location.origin}/withdraw?chain=${chainId}&currency=${currency}&amount=${ethers.formatUnits(amount, decimals[currencyAddress])}&sender=${signer.address}&recipient=${recipient}`;
        try {
            navigator.clipboard.writeText(withdrawLink);
            successPopup('Transfer completed.\nWithdraw link copied to clipboard.');
        } catch (error) {
            warningPopup('Transfer completed.\nFailed to copy to clipboard: ' + error.message + '\nWithdraw link: ' + withdrawLink);
        }
    } catch (error) {
        errorPopup(error.message);
    }
    $('#transferModal').modal('hide');
    $('#transfer-button').prop('disabled', false);
    $('#transfer-button').html('Transfer');
    templateInjection
}

async function deposit() {
    $('#deposit-button').prop('disabled', true);
    $('#deposit-button').html(loadingSpinner);
    const signer = await getSigner();
    const chainId = (await signer.provider.getNetwork()).chainId;
    const compliantTreasuryAddress = addresses[chainId].getAddress('compliantTreasury');
    const compliantTreasury = new ethers.Contract(compliantTreasuryAddress, CompliantTreasuryAbi, signer);
    const currencyAddress = addresses[chainId].getAddress(currency);
    try {
        const amount = ethers.parseUnits($('#receive-amount-input').val(), decimals[currencyAddress]);
        const recipient = signer.address;
        if (currency == 'ETH') {
            const selector = compliantTreasury.interface.getFunction('payEthers', [recipient]).selector;
            await validateEthTransferCompliance(chainId, compliantTreasuryAddress, selector, signer.address, signer.address, amount);
            await compliantTreasury.payEthers(recipient, { value: amount });
        } else {
            const selector = compliantTreasury.interface.getFunction('payTokens', [recipient, currencyAddress, amount]).selector;
            const token = new ethers.Contract(currencyAddress, Erc20Abi, signer);
            await token.approve(compliantTreasuryAddress, amount);
            await validateErc20TransferCompliance(chainId, compliantTreasuryAddress, selector, signer.address, signer.address, currencyAddress, amount);
            await compliantTreasury.payTokens(recipient, currencyAddress, amount);
        }
        successPopup('Deposit completed.');
    } catch (error) {
        console.error(error);
        errorPopup(error.message);
    }
    $('#receiveModal').modal('hide');
    $('#deposit-button').prop('disabled', false);
    $('#deposit-button').html('Deposit');
    templateInjection();
}

async function updateLink() {
    const invoicer = (await getSigner()).address;
    const amount = $('#receive-amount-input').val();
    const invoicerVal = invoicer ? `receiver=${invoicer}` : '';
    const amountVal = amount ? `amount=${amount}` : '';
    const currencyVal = currency ? `currency=${currency}` : '';
    const args = [invoicerVal, amountVal, currencyVal].filter(arg => arg).join('&');
    $('#payment-link').text(`${window.location.origin}/payment${args ? `?${args}` : ''}`);
}

function copyPaymentLink() {
    navigator.clipboard.writeText($('#payment-link').text());

    var paymentLinkControl = $('#payment-link-control').get(0);
    paymentLinkControl.style.transition = '';
    paymentLinkControl.style.setProperty('color', 'green', 'important');
    paymentLinkControl.style.opacity = '1';
    setTimeout(function() {
        paymentLinkControl.style.transition = 'color 1s, opacity 1s';
        paymentLinkControl.style.removeProperty('color');
        paymentLinkControl.style.opacity = '0.75';
    }, 50);
    setTimeout(function() {
        paymentLinkControl.style.transition = '';
    }, 600);
}

async function userPolicy() {
    const signer = await getSigner();
    const chainId = (await signer.provider.getNetwork()).chainId;
    const compliantTreasuryAddress = addresses[chainId].getAddress('compliantTreasury');

    validateUser(chainId, compliantTreasuryAddress).then(function(response) {
        console.log(response);
        if (response.error) {
            errorPopup(`Service error: ${response.error.message}. Please try again later.`);
            return;
        }
        switch (response.result.status) {
            case 'approved':
                successPopup('Your user profile is approved.');
                break;
            case 'approval_required':
                warningPopup('Your user profile requires approval to proceed, please try again later.');
                break;
            case 'rejected':
                errorPopup(`User profile rejected. ${response.result.reason}.`);
                break;
        }
    });
}

onWalletConnect(templateInjection);

$(document).ready(function() {
    window.userPolicy = userPolicy;
    $('#receive-amount-input').on('input', updateLink);
    $('#payment-link-control').click(copyPaymentLink);
    $('#transfer-button').click(transfer);
    $('#deposit-button').click(deposit);
    $('#invoicer-button').click(async function() {
        const signer = await getSigner();
        $('#invoicer-input').val(signer.address);
    });
    const searchParams = new URLSearchParams(window.location.search);
    const wallet = searchParams.has('wallet') ? searchParams.get('wallet') : 'metamask';
    providerInit(wallet);
});
